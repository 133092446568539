<template>
  <LayoutAppContent>
    <template v-if="!userRef.isAuthPending">
      <HomePublic v-if="!isAuthenticated && !isInternal" />
      <HomeExternal v-if="isExternalAuthenticated" />
      <HomeInternal v-if="isAuthenticated && isInternal" />
    </template>
  </LayoutAppContent>
</template>

<script lang="ts">
import { useUser } from "@modernary/mui/utils/authentication";
import { useAuthorization } from "@modernary/mui/utils/authorization";
import { noLabelSchema } from "@modernary/mui/utils/helpers";

import { ref, computed, onMounted, watch, defineComponent } from "vue";
import HomeExternal from "@/components/HomeExternal.vue";
import HomePublic from "@/components/HomePublic.vue";
import { LayoutAppContent } from "@modernary/mui/components/layouts";
import HomeInternal from "@/components/HomeInternal.vue";
import { createAccessRequest } from "@/views/accessRequest/accessRequestApi";

export default defineComponent({
  setup() {
    const { userRef } = useUser();
    const noLabel = noLabelSchema();

    const { authorizationClient, AuthRequest, handlePreempts } = useAuthorization();

    // Example usage of throttledAuthorize -
    // authorizationClient.throttledAuthorize(isBureauAR).then((result) => {
    //   isBureau.value = result?.isSuccess;
    // }, handlePreempts);

    // authorizationClient.throttledAuthorize(isHealthDistrictAR).then((result) => {
    //   isHealthDistrict.value = result?.isSuccess;
    // }, handlePreempts);

    const isInternal = computed(
      () => Boolean(userRef.value?.claims?.some((x: any) => x.type === "role" && x.value === "Internal")), //TODO:TS
    );

    const isAuthenticated = computed(() => userRef.value.isAuthenticated);
    const isExternalAuthenticated = computed(() => isAuthenticated?.value && !isInternal.value);

    return {
      // handleSubmit,
      noLabel,
      isExternalAuthenticated,
      isInternal,
      userRef,
      isAuthenticated,
    };
  },
  components: {
    LayoutAppContent,
    HomeInternal,
    HomeExternal,
    HomePublic,
  },
});
</script>
