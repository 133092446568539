import { RouterView } from "vue-router";
import EntityAudits from "@/components/EntityAudits.vue";
import { EntityDocuments } from "@modernary/mui/components/document";
import { EntityNotes } from "@modernary/mui/components/note";

/** @type {import('vue-router').RouterOptions['routes']} */

const names = {
  projects: "Projects",
  projectList: "ProjectList",
  projectNew: "ProjectNew",
  projectBase: "ProjectBase",
  projectView: "ProjectView",
  projectEdit: "ProjectEdit",
  projectNotes: "ProjectNotes",
  projectNoteEdit: "ProjectNoteEdit",
  projectNoteNew: "ProjectNoteNew",
  projectDocuments: "ProjectDocuments",
  projectDocumentEdit: "ProjectDocumentEdit",
  projectDocumentNew: "ProjectDocumentNew",
  projectAudit: "ProjectAudit",
  projectDeliverables: "ProjectDeliverables",
  projectDeliverableEdit: "ProjectDeliverableEdit",
  projectDeliverableNew: "ProjectDeliverableNew",
  projectSpendRequests: "ProjectSpendRequests",
  projectSpendRequestEdit: "ProjectSpendRequestEdit",
  projectSpendRequestNew: "ProjectSpendRequestNew",
  projectContractTaskOrders: "ProjectContractTaskOrders",
  projectContractTaskOrderEdit: "ProjectContractTaskOrderEdit",
  projectContractTaskOrderNew: "ProjectContractTaskOrderNew",
  projectPurchaseOrders: "ProjectPurchaseOrders",
  projectPurchaseOrderEdit: "ProjectPurchaseOrderEdit",
  projectPurchaseOrderNew: "ProjectPurchaseOrderNew",
  projectInvoices: "ProjectInvoices",
  projectInvoiceEdit: "ProjectInvoiceEdit",
  projectInvoiceNew: "ProjectInvoiceNew",
};

export { names as projectRouteNames };

const projectRoutes = [
  {
    path: "/project",
    name: names.projects,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.projectList },
    children: [
      {
        path: "list",
        name: names.projectList,
        meta: { title: "Projects" },
        component: () => import("@/views/project/ProjectList.vue"),
      },
      {
        path: "new",
        component: () => import("@/views/project/ProjectBase.vue"),
        redirect: { name: names.projectNew },
        props: true,
        children: [
          {
            path: "",
            name: names.projectNew,
            meta: { title: "New Project", isNew: true },
            component: () => import("@/views/project/ProjectEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.projectBase,
        component: () => import("@/views/project/ProjectBase.vue"),
        redirect: { name: names.projectView },
        children: [
          {
            path: "view",
            name: names.projectView,
            meta: { title: "Project View" },
            component: () => import("@/views/project/ProjectView.vue"),
          },
          {
            path: "edit",
            name: names.projectEdit,
            meta: { title: "Project Edit", isForm: true },
            component: () => import("@/views/project/ProjectEdit.vue"),
          },
          {
            path: "notes",
            name: names.projectNotes,
            meta: { title: "Project Notes" },
            component: {
              render: () => <EntityNotes idField="projectId" routeNamePrefix="ProjectNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.projectNoteEdit,
            meta: { title: "Edit Project Note", isForm: true },
            component: () => import("@/views/project/ProjectNote.vue"),
          },
          {
            path: "notes/new",
            name: names.projectNoteNew,
            meta: { title: "Create Project Note", isForm: true },
            component: () => import("@/views/project/ProjectNote.vue"),
          },
          {
            path: "documents",
            name: names.projectDocuments,
            meta: { title: "Project Documents" },
            component: {
              render: () => <EntityDocuments idField="projectId" routeNamePrefix="ProjectDocument" />,
            },
          },
          {
            path: "documents/:documentId(\\d+)",
            name: names.projectDocumentEdit,
            meta: { title: "Edit Project Document", isForm: true },
            component: () => import("@/views/project/ProjectDocument.vue"),
          },
          {
            path: "documents/new",
            name: names.projectDocumentNew,
            meta: { title: "Create Project Document", isForm: true },
            component: () => import("@/views/project/ProjectDocument.vue"),
          },
          {
            path: "deliverables",
            name: names.projectDeliverables,
            meta: { title: "Project Deliverable" },
            component: () => import("@/views/project/DeliverablesChildList.vue"),
          },
          {
            path: "deliverables/:deliverableId(\\d+)",
            name: names.projectDeliverableEdit,
            meta: { title: "Edit Project Deliverable", isForm: true },
            component: () => import("@/views/project/ProjectDeliverable.vue"),
          },
          {
            path: "deliverables/new",
            name: names.projectDeliverableNew,
            meta: { title: "Create Project Deliverable", isForm: true },
            component: () => import("@/views/project/ProjectDeliverable.vue"),
          },
          {
            path: "spendRequests/:spendRequestId(\\d+)",
            name: names.projectSpendRequestEdit,
            meta: { title: "Edit Project SpendRequest", isForm: true },
            component: () => import("@/views/project/ProjectSpendRequest.vue"),
          },
          {
            path: "spendRequests/new",
            name: names.projectSpendRequestNew,
            meta: { title: "Create Project SpendRequest", isForm: true },
            component: () => import("@/views/project/ProjectSpendRequest.vue"),
          },
          {
            path: "contractTaskOrders/:contractTaskOrderId(\\d+)",
            name: names.projectContractTaskOrderEdit,
            meta: { title: "Edit Project Contract Task Order", isForm: true },
            component: () => import("@/views/project/ProjectContractTaskOrder.vue"),
          },
          {
            path: "contractTaskOrders/new",
            name: names.projectContractTaskOrderNew,
            meta: { title: "Create Project Contract Task Order", isForm: true },
            component: () => import("@/views/project/ProjectContractTaskOrder.vue"),
          },
          {
            path: "purchaseOrders/:purchaseOrderId(\\d+)",
            name: names.projectPurchaseOrderEdit,
            meta: { title: "Edit Project Purchase Order", isForm: true },
            component: () => import("@/views/project/ProjectPurchaseOrder.vue"),
          },
          {
            path: "purchaseOrders/new",
            name: names.projectPurchaseOrderNew,
            meta: { title: "Create Project Purchase Order", isForm: true },
            component: () => import("@/views/project/ProjectPurchaseOrder.vue"),
          },
          {
            path: "invoices",
            name: names.projectInvoices,
            meta: { title: "Project Invoice" },
            component: () => import("@/views/project/ProjectInvoices.vue"),
          },
          {
            path: "invoices/:invoiceId(\\d+)",
            name: names.projectInvoiceEdit,
            meta: { title: "Edit Project Invoice", isForm: true },
            component: () => import("@/views/project/ProjectInvoice.vue"),
          },
          {
            path: "invoice/new",
            name: names.projectInvoiceNew,
            meta: { title: "Create Project Invoice", isForm: true },
            component: () => import("@/views/project/ProjectInvoice.vue"),
          },
          {
            path: "activity",
            name: names.projectAudit,
            meta: { title: "Project Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="Project" />,
            },
          },
        ],
      },
    ],
  },
];
export default projectRoutes;
