import { RouterView } from "vue-router";
import EntityAudits from "@/components/EntityAudits.vue";
import { EntityDocuments } from "@modernary/mui/components/document";
import { EntityNotes } from "@modernary/mui/components/note";
// Troubleshoot your routes using this - https://paths.esm.dev

/** @type {import('vue-router').RouterOptions['routes']} */

const names = {
  contracts: "Contracts",
  contractList: "ContractList",
  contractNew: "ContractNew",
  contractBase: "ContractBase",
  contractView: "ContractView",
  contractEdit: "ContractEdit",
  contractNotes: "ContractNotes",
  contractNoteEdit: "ContractNoteEdit",
  contractNoteNew: "ContractNoteNew",
  contractProjects: "ContractProjects",
  contractDocuments: "ContractDocuments",
  contractDocumentEdit: "ContractDocumentEdit",
  contractDocumentNew: "ContractDocumentNew",
  contractAudit: "ContractAudit",
  contractModifications: "ContractModifications",
  contractModificationEdit: "ContractModificationEdit",
  contractModificationNew: "ContractModificationNew",
};

export { names as contractRouteNames };

const contractRoutes = [
  {
    path: "/contract",
    name: names.contracts,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.contractList },
    children: [
      {
        path: "list",
        name: names.contractList,
        meta: { title: "Contracts" },
        component: () => import("@/views/contract/ContractList.vue"),
      },
      {
        path: "new",
        component: () => import("@/views/contract/ContractBase.vue"),
        redirect: { name: names.contractNew },
        props: true,
        children: [
          {
            path: "",
            name: names.contractNew,
            meta: { title: "New Contract", isNew: true },
            component: () => import("@/views/contract/ContractEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.contractBase,
        component: () => import("@/views/contract/ContractBase.vue"),
        redirect: { name: names.contractView },
        children: [
          {
            path: "view",
            name: names.contractView,
            meta: { title: "Contract View" },
            component: () => import("@/views/contract/ContractView.vue"),
          },
          {
            path: "edit",
            name: names.contractEdit,
            meta: { title: "Contract Edit", isForm: true },
            component: () => import("@/views/contract/ContractEdit.vue"),
          },
          {
            path: "notes",
            name: names.contractNotes,
            meta: { title: "Contract Notes" },
            component: {
              render: () => <EntityNotes idField="contractId" routeNamePrefix="ContractNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.contractNoteEdit,
            meta: { title: "Edit Contract Note", isForm: true },
            component: () => import("@/views/contract/ContractNote.vue"),
          },
          {
            path: "notes/new",
            name: names.contractNoteNew,
            meta: { title: "Create Contract Note", isForm: true },
            component: () => import("@/views/contract/ContractNote.vue"),
          },
          {
            path: "projects",
            name: names.contractProjects,
            meta: { title: "Contract Projects" },
            component: () => import("@/views/contract/ProjectChildList.vue"),
          },
          {
            path: "documents",
            name: names.contractDocuments,
            meta: { title: "Contract Documents" },
            component: {
              render: () => <EntityDocuments idField="contractId" routeNamePrefix="ContractDocument" />,
            },
          },
          {
            path: "documents/:documentId(\\d+)",
            name: names.contractDocumentEdit,
            meta: { title: "Edit Contract Document", isForm: true },
            component: () => import("@/views/contract/ContractDocument.vue"),
          },
          {
            path: "documents/new",
            name: names.contractDocumentNew,
            meta: { title: "Create Contract Document", isForm: true },
            component: () => import("@/views/contract/ContractDocument.vue"),
          },
          {
            path: "modifications",
            name: names.contractModifications,
            meta: { title: "Contract Modification" },
            component: () => import("@/views/contract/ContractModifications.vue"),
          },
          {
            path: "modifications/:modificationId(\\d+)",
            name: names.contractModificationEdit,
            meta: { title: "Edit Contract Modification", isForm: true },
            component: () => import("@/views/contract/ContractModification.vue"),
          },
          {
            path: "modifications/new",
            name: names.contractModificationNew,
            meta: { title: "Create Contract Modification", isForm: true },
            component: () => import("@/views/contract/ContractModification.vue"),
          },
          {
            path: "activity",
            name: names.contractAudit,
            meta: { title: "Contract Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="Contract" />,
            },
          },
        ],
      },
    ],
  },
];
export default contractRoutes;
