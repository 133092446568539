import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";

import { apiService, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { IInvoice, IInvoiceNew } from "@/views/project/project";
import { AxiosResponse } from "axios";

const URL = (projectId: string | number) => `projects/${projectId}/invoices`;
const ENTITY_NAME = "Invoice";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_INSIGHT_API_BASE_URL,
};
const api = apiService<IInvoice, IPaging<IInvoice>>(apiHosts.INSIGHT, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getInvoices = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL(params.projectId);

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getInvoiceById = (ids: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.get(URL(ids.projectId) + "/" + ids.id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewInvoice = async (ids: any) => {
  //TODO:TS
  //optionally make any other changes as necessary before returning.
  return { data: { ...ids, invoiceItems: [{ key: -Date.now() }] } } as AxiosResponse<IInvoiceNew, any>;
};

const createInvoice = (invoice: IInvoice, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL(invoice.projectId), invoice, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateInvoice = (invoice: IInvoice, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.put(URL(invoice.projectId) + "/" + invoice.id, invoice, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Invoice} invoice This cannot be a 'ref' to a invoice
 */
const saveInvoice = (invoice: IInvoice, config: IPagingApiAndNotifierConfig = {}) => {
  if (invoice.id) {
    return updateInvoice(invoice, config);
  } else {
    return createInvoice(invoice, config);
  }
};

/**
 * @param {Int} id
 */
const deleteInvoiceById = (fa: IInvoice, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL(fa.projectId) + "/" + fa.id, {
    successNotifCb,
    errorNotifCb,
  });
};

export { deleteInvoiceById, getInvoices, getInvoiceById, getNewInvoice, createInvoice, updateInvoice, saveInvoice };
