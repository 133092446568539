<template>
  <router-view />
  <BaseNotifications></BaseNotifications>
</template>

<script setup lang="tsx">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { BaseNotifications } from "@modernary/mui/components/notifications";
import { useAppNav, useAppSettingsStore } from "@modernary/mui/components/common";
import { routeNames } from "@/routes";

const route = useRoute();
const appSettingsStore = useAppSettingsStore();
const { setNavigationItems, setApplicationTitleComponent } = useAppNav();

const SmallComponent = () => <div class="">{appSettingsStore.pageTitleBase}</div>;
setApplicationTitleComponent(SmallComponent);

// Nav items should be set one time during app initilization / config.
setNavigationItems([
  {
    label: "home",
    className: "",
    showLabel: false,
    items: [
      { label: "Home", name: routeNames.home },
      { label: "Grants", name: routeNames.grants },
      { label: "Contracts", name: routeNames.contracts },
      // { label: "Systems", name: routeNames.systems },
      { label: "Projects", name: routeNames.projects },
    ],
  },
]);
</script>
