import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";

import { apiService, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { IModification, IModificationNew } from "@/views/contract/contract";
import { AxiosResponse } from "axios";

const URL = (contractId: string | number) => `contracts/${contractId}/modifications`;
const ENTITY_NAME = "Modification";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_INSIGHT_API_BASE_URL,
};
const api = apiService<IModification, IPaging<IModification>>(apiHosts.INSIGHT, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getModifications = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL(params.contractId);

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getModificationById = (ids: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.get(URL(ids.contractId) + "/" + ids.id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewModification = async (ids: any) => {
  //TODO:TS
  //optionally make any other changes as necessary before returning.
  return { data: { ...ids } } as AxiosResponse<IModificationNew, any>;
};

const createModification = (modification: IModification, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL(modification.contractId), modification, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateModification = (modification: IModification, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.put(URL(modification.contractId) + "/" + modification.id, modification, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Modification} modification This cannot be a 'ref' to a modification
 */
const saveModification = (modification: IModification, config: IPagingApiAndNotifierConfig = {}) => {
  if (modification.id) {
    return updateModification(modification, config);
  } else {
    return createModification(modification, config);
  }
};

/**
 * @param {Int} id
 */
const deleteModificationById = (fa: IModification, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL(fa.contractId) + "/" + fa.id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteModificationById,
  getModifications,
  getModificationById,
  getNewModification,
  createModification,
  updateModification,
  saveModification,
};
