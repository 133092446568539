<template>
  <div class="h-full overflow-auto bg-neutral-100">
    <div class="">
      <!-- content -->
      <div class="mx-auto mb-5 mt-5 max-w-5xl px-2">
        <div
          class="mx-0 mb-4 mt-4 bg-sky-50 shadow sm:mx-5 sm:my-5 sm:rounded-lg md:mx-8 md:my-8 xl:mx-auto xl:max-w-5xl"
        >
          <!-- Card Content goes here -->
          <div class="justify-between px-4 py-5 sm:p-10 lg:flex">
            <div>
              <h1 class="text-2xl font-extrabold">
                {{ appSettingsStore.applicationTitle }}
                <!-- <span class="font-semibold">({{ appSettingsStore.pageTitleBase }})</span> -->
              </h1>
              <h2 class="mt-2 text-xl text-neutral-500 md:mt-1">
                <span class="font-semibold">Welcome, {{ userRef.firstName }}</span>
              </h2>
              <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
                <p>Insight is a grant, contract, and project tracking app.</p>
              </div>
            </div>
            <div class="mt-6 items-start justify-start gap-5 space-y-5 sm:flex sm:space-y-0 lg:mt-0">
              <LogoutButton :as="BaseButton" variant="priority-hollow" class="w-full sm:w-auto" label="Sign Out" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="sticky top-[100vh] mt-20 flex h-10 flex-col items-center p-2">
      <MdrnAttribution />
    </footer>
  </div>
</template>

<script lang="ts">
import { InitialBadge } from "@modernary/mui/components/badge";
import { BaseButton } from "@modernary/mui/components/button";
import { MdrnAttribution, useAppNav, useAppSettingsStore } from "@modernary/mui/components/common";
import { LoginButton, LogoutButton, RegisterButton, useUser } from "@modernary/mui/utils/authentication";
import { useAuthorization } from "@modernary/mui/utils/authorization";

import { routeNames } from "@/routes";

import { defineComponent } from "vue";

// Home content for unauth'd users
export default defineComponent({
  name: "HomeExternal",
  setup() {
    const { userRef } = useUser();
    const { authorizationClient, AuthRequest, handlePreempts } = useAuthorization();
    const appSettingsStore = useAppSettingsStore();

    const { navigationItems, userNavigationItems } = useAppNav();

    return {
      userRef,
      routeNames,
      BaseButton,
      appSettingsStore,
    };
  },
  components: {
    BaseButton,
    InitialBadge,
    LoginButton,
    RegisterButton,
    MdrnAttribution,
    LogoutButton,
  },
});
</script>
