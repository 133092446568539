import contractService from "@/views/contract/contractService";
import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";

import { IContract, IContractNew } from "./contract";
import { AxiosResponse } from "axios";

const URL = "contracts";
const ENTITY_NAME = "Contract";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_INSIGHT_API_BASE_URL,
};
const api = apiService<IContract, IPaging<IContract>>(apiHosts.INSIGHT, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getContracts = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getContractById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewContract = async (ids: any) => {
  //TODO: ts
  //optionally make any other changes as necessary before returning.
  const defaultApp = {
    data: {
      ...contractService.getInitialData(),
      ...ids,
    },
  } as AxiosResponse<IContractNew, any>;

  return defaultApp;
};

/**
 *
 * @param {Object} ids Object having either an `id` for an Application or a `facilitySiteId`, `permitId`, and type
 * @param {ApiOptions} config
 * @returns {api.get}
 */

type ContractIds = {
  id?: string | number;
};

const createContract = (contract: IContract, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, contract, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateContract = (contract: IContract, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + contract.id, contract, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Contract} contract This cannot be a 'ref' to a contract
 */
const saveContract = (contract: IContract, config = {}) => {
  if (contract.id) {
    return updateContract(contract, config);
  } else {
    return createContract(contract, config);
  }
};

/**
 * @param {Int} id
 */
const deleteContractById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteContractById,
  getContracts,
  getContractById,
  getNewContract,
  createContract,
  updateContract,
  saveContract,
};
