import { IContract } from "@/views/contract/contract";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: IContract) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}
function getInitialData() {
  const result = {} as IContract; //TODO: ts
  return addAuthRequestFields(result);
}
function responseAdapter(response: { data: IContract }) {
  addAuthRequestFields(response.data);
  return response.data;
}
function requestAdapter(data: IContract) {
  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit(data: IContract) {}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: IContract) {}
