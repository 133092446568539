import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";

import { apiService, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { IPurchaseOrder, IPurchaseOrderNew } from "@/views/project/project";
import { AxiosResponse } from "axios";

const URL = (projectId: string | number) => `projects/${projectId}/purchaseOrders`;
const ENTITY_NAME = "Purchase Order";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_INSIGHT_API_BASE_URL,
};
const api = apiService<IPurchaseOrder, IPaging<IPurchaseOrder>>(apiHosts.INSIGHT, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getPurchaseOrders = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL(params.projectId);

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getPurchaseOrderById = (ids: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.get(URL(ids.projectId) + "/" + ids.id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewPurchaseOrder = async (ids: any) => {
  //TODO:TS
  //optionally make any other changes as necessary before returning.
  return { data: { ...ids } } as AxiosResponse<IPurchaseOrderNew, any>;
};

const createPurchaseOrder = (purchaseOrder: IPurchaseOrder, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL(purchaseOrder.projectId), purchaseOrder, {
    successNotifCb,
    errorNotifCb,
  });
};

const updatePurchaseOrder = (purchaseOrder: IPurchaseOrder, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.put(URL(purchaseOrder.projectId) + "/" + purchaseOrder.id, purchaseOrder, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {PurchaseOrder} purchaseOrder This cannot be a 'ref' to a purchaseOrder
 */
const savePurchaseOrder = (purchaseOrder: IPurchaseOrder, config: IPagingApiAndNotifierConfig = {}) => {
  if (purchaseOrder.id) {
    return updatePurchaseOrder(purchaseOrder, config);
  } else {
    return createPurchaseOrder(purchaseOrder, config);
  }
};

/**
 * @param {Int} id
 */
const deletePurchaseOrderById = (fa: IPurchaseOrder, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL(fa.projectId) + "/" + fa.id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deletePurchaseOrderById,
  getPurchaseOrders,
  getPurchaseOrderById,
  getNewPurchaseOrder,
  createPurchaseOrder,
  updatePurchaseOrder,
  savePurchaseOrder,
};
