import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";

import { apiService, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { IContractTaskOrder, IContractTaskOrderNew } from "@/views/project/project";
import type { AxiosResponse } from "axios";

const URL = (projectId: string | number) => `projects/${projectId}/contractTaskOrders`;
const ENTITY_NAME = "Contract Task Order";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_INSIGHT_API_BASE_URL,
};
const api = apiService<IContractTaskOrder, IPaging<IContractTaskOrder>>(apiHosts.INSIGHT, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getContractTaskOrders = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL(params.projectId);

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getContractTaskOrderById = (ids: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.get(URL(ids.projectId) + "/" + ids.id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewContractTaskOrder = async (ids: any) => {
  //TODO:TS
  //optionally make any other changes as necessary before returning.
  return { data: { ...ids } } as AxiosResponse<IContractTaskOrderNew, any>;
};

const createContractTaskOrder = (contractTaskOrder: IContractTaskOrder, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL(contractTaskOrder.projectId), contractTaskOrder, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateContractTaskOrder = (contractTaskOrder: IContractTaskOrder, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.put(URL(contractTaskOrder.projectId) + "/" + contractTaskOrder.id, contractTaskOrder, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {ContractTaskOrder} contractTaskOrder This cannot be a 'ref' to a contractTaskOrder
 */
const saveContractTaskOrder = (contractTaskOrder: IContractTaskOrder, config: IPagingApiAndNotifierConfig = {}) => {
  if (contractTaskOrder.id) {
    return updateContractTaskOrder(contractTaskOrder, config);
  } else {
    return createContractTaskOrder(contractTaskOrder, config);
  }
};

/**
 * @param {Int} id
 */
const deleteContractTaskOrderById = (fa: IContractTaskOrder, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL(fa.projectId) + "/" + fa.id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteContractTaskOrderById,
  getContractTaskOrders,
  getContractTaskOrderById,
  getNewContractTaskOrder,
  createContractTaskOrder,
  updateContractTaskOrder,
  saveContractTaskOrder,
};
