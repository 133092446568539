import { IProject } from "@/views/project/project";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: IProject) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}
function getInitialData() {
  const result = {} as IProject; //TODO: ts
  return addAuthRequestFields(result);
}
function responseAdapter(response: { data: IProject }) {
  addAuthRequestFields(response.data);
  return response.data;
}
function requestAdapter(data: IProject) {
  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit(data: IProject) {}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: IProject) {}
