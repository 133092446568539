import { RouterView } from "vue-router";
import EntityAudits from "@/components/EntityAudits.vue";
import { EntityDocuments } from "@modernary/mui/components/document";
import { EntityNotes } from "@modernary/mui/components/note";
// Troubleshoot your routes using this - https://paths.esm.dev

/** @type {import('vue-router').RouterOptions['routes']} */

const names = {
  grants: "Grants",
  grantList: "GrantList",
  grantNew: "GrantNew",
  grantBase: "GrantBase",
  grantView: "GrantView",
  grantEdit: "GrantEdit",
  grantNotes: "GrantNotes",
  grantNoteEdit: "GrantNoteEdit",
  grantNoteNew: "GrantNoteNew",
  grantDocuments: "GrantDocuments",
  grantDocumentEdit: "GrantDocumentEdit",
  grantDocumentNew: "GrantDocumentNew",
  grantAudit: "GrantAudit",
  grantContacts: "GrantContactDatas",
  grantContactEdit: "GrantContactDataEdit",
  grantContactNew: "GrantContactDataNew",
};

export { names as grantRouteNames };

const grantRoutes = [
  {
    path: "/grant",
    name: names.grants,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.grantList },
    children: [
      {
        path: "list",
        name: names.grantList,
        meta: { title: "Grants" },
        component: () => import("@/views/grant/GrantList.vue"),
      },
      {
        path: "new",
        component: () => import("@/views/grant/GrantBase.vue"),
        redirect: { name: names.grantNew },
        props: true,
        children: [
          {
            path: "",
            name: names.grantNew,
            meta: { title: "New Grant", isNew: true },
            component: () => import("@/views/grant/GrantEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.grantBase,
        component: () => import("@/views/grant/GrantBase.vue"),
        redirect: { name: names.grantView },
        children: [
          {
            path: "view",
            name: names.grantView,
            meta: { title: "Grant View" },
            component: () => import("@/views/grant/GrantView.vue"),
          },
          {
            path: "edit",
            name: names.grantEdit,
            meta: { title: "Grant Edit", isForm: true },
            component: () => import("@/views/grant/GrantEdit.vue"),
          },
          {
            path: "contactDatas",
            name: names.grantContacts,
            meta: { title: "Grant Contacts" },
            component: () => import("@/views/grant/GrantContactDatas.vue"),
          },
          {
            path: "contactDatas/:contactDataId(\\d+)",
            name: names.grantContactEdit,
            meta: { title: "Edit Grant Contact", isForm: true },
            component: () => import("@/views/grant/GrantContactData.vue"),
          },
          {
            path: "contactDatas/new",
            name: names.grantContactNew,
            meta: { title: "Create Grant Contact", isForm: true },
            component: () => import("@/views/grant/GrantContactData.vue"),
          },
          {
            path: "notes",
            name: names.grantNotes,
            meta: { title: "Grant Notes" },
            component: {
              render: () => <EntityNotes idField="grantId" routeNamePrefix="GrantNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.grantNoteEdit,
            meta: { title: "Edit Grant Note", isForm: true },
            component: () => import("@/views/grant/GrantNote.vue"),
          },
          {
            path: "notes/new",
            name: names.grantNoteNew,
            meta: { title: "Create Grant Note", isForm: true },
            component: () => import("@/views/grant/GrantNote.vue"),
          },
          {
            path: "documents",
            name: names.grantDocuments,
            meta: { title: "Grant Documents" },
            component: {
              render: () => <EntityDocuments idField="grantId" routeNamePrefix="GrantDocument" />,
            },
          },
          {
            path: "documents/:documentId(\\d+)",
            name: names.grantDocumentEdit,
            meta: { title: "Edit Grant Document", isForm: true },
            component: () => import("@/views/grant/GrantDocument.vue"),
          },
          {
            path: "documents/new",
            name: names.grantDocumentNew,
            meta: { title: "Create Grant Document", isForm: true },
            component: () => import("@/views/grant/GrantDocument.vue"),
          },
          {
            path: "activity",
            name: names.grantAudit,
            meta: { title: "Grant Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="Grant" />,
            },
          },
        ],
      },
    ],
  },
];
export default grantRoutes;
