import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";

import { apiService, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { IDeliverable, IDeliverableNew, type CanDeleteDeliverable } from "@/views/project/project";
import { AxiosResponse } from "axios";

const URL = (projectId: string | number) => `projects/${projectId}/deliverables`;
const ENTITY_NAME = "Deliverable";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_INSIGHT_API_BASE_URL,
};
const api = apiService<IDeliverable, IPaging<IDeliverable>>(apiHosts.INSIGHT, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getDeliverables = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL(params.projectId);

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getDeliverableById = (ids: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.get(URL(ids.projectId) + "/" + ids.id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewDeliverable = async (ids: any) => {
  //TODO:TS
  //optionally make any other changes as necessary before returning.
  return { data: { ...ids } } as AxiosResponse<IDeliverableNew, any>;
};

const createDeliverable = (deliverable: IDeliverable, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL(deliverable.projectId), deliverable, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateDeliverable = (deliverable: IDeliverable, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.put(URL(deliverable.projectId) + "/" + deliverable.id, deliverable, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Deliverable} deliverable This cannot be a 'ref' to a deliverable
 */
const saveDeliverable = (deliverable: IDeliverable, config: IPagingApiAndNotifierConfig = {}) => {
  if (deliverable.id) {
    return updateDeliverable(deliverable, config);
  } else {
    return createDeliverable(deliverable, config);
  }
};

/**
 * @param {Int} id
 */
const deleteDeliverableById = (ids: IDeliverable, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL(ids.projectId) + "/" + ids.id, {
    successNotifCb,
    errorNotifCb,
  });
};

const canDeleteApi = apiService<CanDeleteDeliverable, IPaging<CanDeleteDeliverable>>(apiHosts.INSIGHT, axiosParams);
const canDeleteDeliverable = (ids: IDeliverable, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return canDeleteApi.get(URL(ids.projectId) + "/can-delete/" + ids.id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteDeliverableById,
  getDeliverables,
  getDeliverableById,
  getNewDeliverable,
  createDeliverable,
  updateDeliverable,
  saveDeliverable,
  canDeleteDeliverable,
};
