import Home from "./views/Home.vue";
import { NotAuthorized, NotFound } from "@modernary/mui/components/common";
import { EntityAuditDetailed } from "@modernary/mui/components/audit";
// import loginRoutes from "./views/login/loginRoutes";
import { default as contractRoutes, contractRouteNames } from "./views/contract/contractRoutes";
import { default as grantRoutes, grantRouteNames } from "./views/grant/grantRoutes";
import { default as projectRoutes, projectRouteNames } from "./views/project/projectRoutes";

// Troubleshoot your routes using this - https://paths.esm.dev

export const routeNames = {
  home: "Home",
  notAuthorized: "NotAuthorized",
  ...contractRouteNames,
  ...grantRouteNames,
  ...projectRouteNames,
};

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
  { path: "/", component: Home, name: routeNames.home, meta: { title: "Home" } },
  {
    path: "/unauthorized",
    component: NotAuthorized,
    name: routeNames.notAuthorized,
    meta: { title: "Not Authorized" },
  },
  ...contractRoutes,
  ...grantRoutes,
  ...projectRoutes,
  // ...loginRoutes,
  { path: "/:path(.*)", component: NotFound },
];
