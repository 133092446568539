import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";

import { apiService, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { ISpendRequest, ISpendRequestNew } from "@/views/project/project";
import { AxiosResponse } from "axios";

const URL = (projectId: string | number) => `projects/${projectId}/spendRequests`;
const ENTITY_NAME = "ETS-205 Form";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_INSIGHT_API_BASE_URL,
};
const api = apiService<ISpendRequest, IPaging<ISpendRequest>>(apiHosts.INSIGHT, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getSpendRequests = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL(params.projectId);

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getSpendRequestById = (ids: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.get(URL(ids.projectId) + "/" + ids.id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewSpendRequest = async (ids: any) => {
  //TODO:TS
  //optionally make any other changes as necessary before returning.
  return { data: { ...ids } } as AxiosResponse<ISpendRequestNew, any>;
};

const createSpendRequest = (spendRequest: ISpendRequest, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL(spendRequest.projectId), spendRequest, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateSpendRequest = (spendRequest: ISpendRequest, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.put(URL(spendRequest.projectId) + "/" + spendRequest.id, spendRequest, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {SpendRequest} spendRequest This cannot be a 'ref' to a spendRequest
 */
const saveSpendRequest = (spendRequest: ISpendRequest, config: IPagingApiAndNotifierConfig = {}) => {
  if (spendRequest.id) {
    return updateSpendRequest(spendRequest, config);
  } else {
    return createSpendRequest(spendRequest, config);
  }
};

/**
 * @param {Int} id
 */
const deleteSpendRequestById = (fa: ISpendRequest, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL(fa.projectId) + "/" + fa.id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteSpendRequestById,
  getSpendRequests,
  getSpendRequestById,
  getNewSpendRequest,
  createSpendRequest,
  updateSpendRequest,
  saveSpendRequest,
};
