import { IGrant, IContactData } from "@/views/grant/grant";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: IGrant) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}
function getInitialData() {
  const result = {} as IGrant; //TODO: ts
  return addAuthRequestFields(result);
}
function responseAdapter(response: { data: IGrant }) {
  addAuthRequestFields(response.data);
  if (response.data.contactData) {
    response.data.contactDatas = JSON.parse(response.data.contactData);
    //TODO: Mask Bug phone number only set to be undefined to fix formkit bug. Once formkit resolves bug remove loop/if
    response.data.contactDatas.forEach((contact) => {
      if (!contact.phone) {
        contact.phone = undefined;
      }
    });
  } else {
    response.data.contactDatas = [];
  }
  //TODO: TS
  return response.data;
}
function requestAdapter(data: IGrant) {
  if (data.contactDatas) {
    data.contactData = JSON.stringify(data.contactDatas);
    //TODO: Mask Bug remove this once the loop/if above is removed.
    data.contactDatas.forEach((contact) => {
      if (contact.phone === undefined) {
        contact.phone = null;
      }
    });
  }
  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit(data: IGrant) {}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: IGrant) {}
